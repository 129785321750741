import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function HomeScreen() {
  const { id } = useParams();
  const [val, setVal] = useState("")
  const [paid, setPaid] = useState(false)

  const openRazorpay = () => {
    const options = {
      key: 'rzp_test_0YRZ0NPcaCCQ2Q', 
      amount: `${val}00`, 
      currency: 'INR',
      name: 'Milano Coffee',
      description: 'Test payment',
      handler: function (response) {
        // Payment success callback
        console.log(response);
        setData()
        setPaid(true)
      },
      prefill: {
        name: 'Milano Coffee',
        email: 'demo@milanocoffee.in',
        contact: '9999999999',
      },
      theme: {
        color: "#F37254"
    }
    };

    const razorpayInstance = new window.Razorpay(options);

    razorpayInstance.open();
  };


  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if(val != null && val != undefined && val != "*a#" && val != "null" && val != ""){
        openRazorpay()
        console.log( typeof(parseInt(val)))
    }
    // console.log(parseInt(val))
  },[val])

  async function getData() {
    fetch(
      `https://milano-payment-server-default-rtdb.firebaseio.com/${id}.json`
    ).then((e) => {return(e.json())}).then((e) => { setVal(eval(`e.${id}`)) })
  }

  async function setData() {
    fetch(
      `https://milano-payment-server-default-rtdb.firebaseio.com/${id}.json`,{
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(
            {
                [id]: "*a#"
            }
        )
      }).then((e) => {return(e.json())}).then((e) => { console.log("collect your drink") })
  }

  return(
    <div>
        {val == null || val == undefined || val == "null"  ? (
            <div style={{width: '100vw', height: '100vh', backgroundColor: 'black', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <h1 style={{color: "white", textAlign: 'center'}}>No Drinks Found<br/>Select a drink and scan again</h1>
            </div>
        ):(
            <div style={{width: '100vw', height: '100vh', backgroundColor: 'black', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                {val != "null" || val != "" && (
                  <h1 style={{color: "white"}}>Machine is currently dispensing a drink</h1>
                )}
                {paid && (
                    <h1 style={{color: "white"}}>Collect your drink</h1>
                    )
                }
            </div>
        )}
    </div>
  );
}
