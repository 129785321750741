import React from 'react'

import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import HomeScreen from './Screens/HomeScreen';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/:id" element={<HomeScreen />} />
      </Routes>
    </BrowserRouter>
  )
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
